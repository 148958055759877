import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    width: '100%',
    color: theme.palette.text.primary,
    fontFamily: 'Montserrat',
    [theme.breakpoints.down(1240)]: {
      padding: '3rem 0rem',
      marginTop: '-3rem',
      background: '#eaeaea',
    },
    '& .uppercase': {
      textTransform: 'uppercase',
      fontSize: '0.75rem',
      fontWeight: 500,
    },
    '& .no-uppercase': {
      fontSize: '0.875rem',
      fontWeight: 500,
      fontFamily: 'Montserrat',
    },
  },
  nameSpent: {
    marginBottom: '1rem',
    [theme.breakpoints.down(1240)]: {
      margin: '0 auto',
    },
    '& >span': {
      marginBottom: '1rem',
      display: 'block',
      textTransform: 'uppercase',
      fontSize: '0.75rem',
      fontWeight: 500,
    },
    '& >div': {
      display: 'flex',
      alignItems: 'center',
      gap: '2rem',
      [theme.breakpoints.down(1240)]: {
        flexWrap: 'wrap',
        gap: '1rem',
      },
    },
    [theme.breakpoints.down(1240)]: {
      '& .name': {
        width: '15rem',
      },
      '& .number': {
        width: '7rem',
      },
      '& .calendar': {
        width: '7rem',
      },
    },
  },
  isRepeated: {
    '& >div': {
      display: 'flex',
      alignItems: 'end',
      gap: '2rem',
      [theme.breakpoints.down(1240)]: {
        flexWrap: 'wrap',
        gap: '1rem',
      },
    },
    [theme.breakpoints.down(1240)]: {
      '& .name': {
        width: '15rem',
      },
      '& .number': {
        width: '7rem',
      },
      '& .calendar': {
        width: '7rem',
      },
      width: '15rem',
      margin: '0 auto',
    },
    '& .column': {
      flexDirection: 'column',
      display: 'flex',
      '& span': {
        textTransform: 'uppercase',
        fontSize: '0.75rem',
        fontWeight: 500,
        marginBottom: '0.5rem',
        display: 'block',
      },
    },
    '& >span': {
      marginBottom: '1rem',
      display: 'block',
      textTransform: 'uppercase',
      fontSize: '0.75rem',
      fontWeight: 500,
    },
  },
  category: {
    margin: '-4rem auto 0',
    maxWidth: '46rem',
    background: 'white',
    padding: '3rem 5rem 6rem',
    borderRadius: 13,
    [theme.breakpoints.down(1240)]: {
      justifyContent: 'center',
      margin: '1.5rem auto 0',
      padding: '0.5rem',
      width: '90%',
      maxWidth: '41rem',
    },
  },
  categoryCheckbox: {
    margin: '1rem auto 0',
    padding: '1rem 5rem 1rem',
    maxWidth: '46rem',
    background: 'white',
    borderRadius: '13px',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    [theme.breakpoints.down(1240)]: {
      justifyContent: 'center',
      margin: '1.5rem auto 0',
      padding: '1rem',
      width: '90%',
      maxWidth: '41rem',
    },
  },
  CheckboxInput: {
    '& .css-ptiqhd-MuiSvgIcon-root': {
      color: theme.palette.primary.dark,
    },
  },
  filtersRow: {
    '& span': {
      color: '#646464',
      fontSize: '0.9rem',
      textAlign: 'left',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'normal',
    },
  },
  buttons: {
    margin: '2rem auto 2rem',
    maxWidth: '46rem',
    display: 'flex',
    justifyContent: 'end',
    [theme.breakpoints.down(1240)]: {
      flexDirection: 'column-reverse',
      width: '21rem',
      margin: '0 auto',
      marginTop: '2rem',
    },
    '& .first': {
      width: '17.8rem',
    },
    '& .secondary': {
      width: '10rem',
      textDecoration: 'none',
    },
    '& button': {
      [theme.breakpoints.down(1240)]: {
        margin: '0 auto',
      },
    },
  },
  categories: {
    marginTop: '2rem',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    [theme.breakpoints.down(1240)]: {
      width: '100%',
      gap: '0.5rem',
      marginTop: '1rem',
      '& img': {
        width: '3.125rem!important',
        height: 'auto!important',
      },
    },
  },
  item: {
    cursor: 'pointer',
    width: 101,
    textAlign: 'center',
    [theme.breakpoints.down(1240)]: {
      width: '22%',
      lineHeight: 1,
      marginBottom: '0.5rem',
    },
    '& img': {
      width: '96px',
      height: '96px',
      margin: '0 auto',
      [theme.breakpoints.down(1240)]: {
        margin: '0',
      },
      [theme.breakpoints.up(1240)]: {
        width: '50px',
        height: '50px',
      },
    },
    '& span': {
      fontFamily: 'Nunito',
      fontSize: '0.75rem',
      fontWeight: 200,
      [theme.breakpoints.down(1240)]: {},
    },
  },
  item12: {
    cursor: 'pointer',
    width: 101,
    textAlign: 'center',
    '& img': {
      margin: '0 auto',
      width: '5.05rem',
    },
  },

  buttons1: {
    width: '21rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    gap: '2rem',
    marginBottom: '2rem',
  },
  select: {
    opacity: '1!important',
    width: '5rem',
    textAlign: 'center',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  unselect: {
    cursor: 'pointer',
  },
  itemCategory: {
    cursor: 'pointer',
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
    padding: '0.3rem 0',
    borderBottom: `1px solid #d1d1d1`,
    justifyContent: 'space-between',
    '& >div': {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
    },
    [theme.breakpoints.down(1240)]: {
      padding: '1rem 0',
    },
    '& img': {
      width: '1.23rem',
      height: '1.23rem',
    },
  },
  subcategories: {
    color: '#959595',
    [theme.breakpoints.down(1240)]: {
      fontSize: '0.875rem',
    },
  },
  subcategoriesDiv: {
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    '& >div:last-child': {
      border: 'none',
    },
  },
  button: {
    fontSize: '1.25rem',
    padding: 0,
    paddingRight: '2rem',
    fontWeight: 300,
  },
  categoryOnSubcat: {
    display: 'flex',
    marginTop: '1rem',
    marginBottom: '1.64rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down(1240)]: {
      gap: '0.5rem',
    },
    '& img': {
      width: 96,
      height: 96,
      [theme.breakpoints.down(1240)]: {
        width: '4rem',
        height: '4rem',
      },
    },
    '& span': {
      fontFamily: 'Montserrat',
      fontSize: '0.75rem',
      fontWeight: 200,
    },
  },
  text: {
    width: '60%',
    minWidth: 300,
    fontSize: '1.25rem!important',
    fontWeight: 'normal',
    [theme.breakpoints.down(1240)]: {
      width: '100%',
      minWidth: 'auto',
    },
  },
  textFieldButton: {
    width: '100%',
  },
  borderIcon: {
    borderRadius: '100%',
    border: '1px solid rgba(0,0,0,0.2)',
    padding: '0.8rem',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down(1240)]: {
      width: '4rem',
      margin: '0 auto',
    },
    [theme.breakpoints.up(1240)]: {
      width: '75px',
      height: '75px',
      margin: '0 auto',
    },
  },
  borderIconTwo: {
    borderRadius: '100%',
    border: '1px solid rgba(0,0,0,0.2)',
    padding: '1rem',
    [theme.breakpoints.down(1240)]: {
      padding: '0.4rem',
    },
    '& img': {
      width: '4rem',
      height: '4rem',
      [theme.breakpoints.down(1240)]: {
        height: '3rem',
        width: '5rem',
      },
    },
  },
}));
