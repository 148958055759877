import React, { useState } from 'react';
import { Checkbox, FormControlLabel, useMediaQuery } from '@material-ui/core';
import { useStyles } from './Movement.style';
import { useAuth } from '@hooks';
import { ButtonPrimary, ButtonText } from '@components/Reusables';
import { FinerioAccount, FinerioTransaction } from '@interfaces';
import { editFinerioTransaction } from '@apollo';
import { useApolloClient } from '@apollo/client';
import { navigate } from 'gatsby';
import { Route } from '@interfaces';
import { getFinerioSubcategories } from '@apollo';
import _ from 'lodash';
import CloseIcon from '@material-ui/icons/Close';

// TODO: Fix this to use the subcategories grouped by category from database and create a getSubcategoryIcon

interface LayoutProps {
  textButton?: string;
  className?: string;
  movement?: FinerioTransaction;
  movementAccount?: FinerioAccount;
  newAmount?: number;
  newDate?: Date;
  newDescription: string;
}

export const Movement: React.FC<LayoutProps> = ({
  className,
  textButton,
  movement,
  movementAccount,
  newAmount,
  newDate,
  newDescription,
}) => {
  const isMobile = useMediaQuery('(max-width: 1240px)');
  const { isLoggedIn } = useAuth();
  const classes = useStyles();
  const client = useApolloClient();
  const { finerioSubcategories } = getFinerioSubcategories();
  const [categorySelect, setCategorySelect] = useState<string>('all');
  const [selectedSubcategoryId, setSelectedSubcategoryId] = useState<string>();
  const [onEveryTransactionSameName, setOnEveryTransactionSameName] = useState<boolean>()
  const [forFutureMovements, setForFutureMovements] = useState<boolean>()

  const onSave = () => {
    if (movementAccount?.bankId != 0) {
      editFinerioTransaction(client, {
        transactionId: String(movement?.id) ?? '',
        onEveryTransactionSameName: onEveryTransactionSameName,
        forFutureMovements: forFutureMovements,
        subcategory: selectedSubcategoryId ?? movement?.subcategoryId.subcategoryId ?? "000-0007-0006",
      });
    } else {
      editFinerioTransaction(client, {
        transactionId: String(movement?.id) ?? '',
        description: newDescription,
        amount: newAmount,
        date: newDate,
        onEveryTransactionSameName: onEveryTransactionSameName,
        forFutureMovements: forFutureMovements,
        subcategory: selectedSubcategoryId ?? movement?.subcategoryId.subcategoryId ?? "000-0007-0006",
      });
    }
    navigate(Route.finance + '?#habits');
  };

  const subcategoriesGrouped = _.groupBy(finerioSubcategories, function (item) {
    return item.FinerioCategory.name;
  });
  return (
    <div className={`${classes.container} ${className || ''} `}>
      <section className={classes.category}>
        <div>
          {categorySelect === 'all' ? (
            <div className={classes.categories}>
              {Object.entries(subcategoriesGrouped).map((category) => {
                return (
                  <div
                    onClick={() => {
                      setCategorySelect(category[1][0].FinerioCategory.name);
                    }}
                    className={classes.item}
                    key={category[1][0].FinerioCategory.id}
                  >
                    <div className={classes.borderIcon}>
                      <img src={category[1][0].FinerioCategory.iconColor} />
                    </div>
                    <span>{category[1][0].FinerioCategory.name}</span>
                  </div>
                );
              })}
            </div>
          ) : (
            <section className={` ${classes.flex}`}>
              <div className={classes.categoryOnSubcat}>
                <div className={classes.borderIconTwo}>
                  <img
                    src={
                      subcategoriesGrouped[categorySelect][0].FinerioCategory.iconColor
                    }
                  />
                </div>
                <span className={classes.text}>
                  {subcategoriesGrouped[categorySelect][0].FinerioCategory.name}
                </span>
                <ButtonText
                  className={classes.button}
                  text={
                    <>
                      Cambiar <br /> categoría
                    </>
                  }
                  onClick={() => {
                    setCategorySelect('all');
                  }}
                />
              </div>
              <div>
                <span className="no-uppercase">
                  Selecciona una sub-categoría
                </span>
                <div className={classes.subcategoriesDiv}>
                    {subcategoriesGrouped[categorySelect].map((category) => {

                      if (category.visible) {
                        
                        return (
                          <>
                            {selectedSubcategoryId === category.subcategoryId ? (
                              <div
                                onClick={() => {
                                  selectedSubcategoryId === category.subcategoryId
                                    ? setSelectedSubcategoryId(undefined)
                                    : setSelectedSubcategoryId(category.subcategoryId);
                                }}
                                key={category.subcategoryId}
                                className={classes.itemCategory}
                              >
                                <div>
                                  {category.subcategoryId === selectedSubcategoryId ? (
                                    <img src="https://rokinapp-images.s3.amazonaws.com/myHabits/subcategory/livingPalace/Action+_+check+circle+(1).svg" />
                                  ) : (
                                    <img src={category.iconColor} />
                                  )}
                                  <div className={classes.subcategories}>
                                    {category.description}
                                  </div>
                                </div>
                                {category.subcategoryId === selectedSubcategoryId && (
                                  <CloseIcon
                                    onClick={() => setSelectedSubcategoryId(undefined)}
                                  />
                                )}
                              </div>
                            ) : (
                              !selectedSubcategoryId && (
                                <div
                                  onClick={() => {
                                    selectedSubcategoryId === category.subcategoryId
                                      ? setSelectedSubcategoryId(undefined)
                                      : setSelectedSubcategoryId(
                                        category.subcategoryId
                                      );
                                  }}
                                  key={category.subcategoryId}
                                  className={classes.itemCategory}
                                >
                                  <div>
                                    {category.subcategoryId ===
                                      selectedSubcategoryId ? (
                                      <img src="https://rokinapp-images.s3.amazonaws.com/myHabits/subcategory/livingPalace/Action+_+check+circle+(1).svg" />
                                    ) : (
                                      <img src={category.iconColor} />
                                    )}
                                    <div className={classes.subcategories}>
                                      {category.description}
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </>
                        )
                      }
                    }
                  )}
                </div>
              </div>
            </section>
          )}
        </div>
      </section>
      <section className={classes.categoryCheckbox}>
        <FormControlLabel
          className={classes.filtersRow}
          control={
            <Checkbox
              className={classes.CheckboxInput}
              size="small"
              checked={onEveryTransactionSameName}
              onChange={(e) =>
                e.target.checked
                  ? setOnEveryTransactionSameName(true)
                  : setOnEveryTransactionSameName(false)
              }
            />
          }
          label={"Aplicar este cambio a todos los movimientos con el mismo nombre"}
        />
        {/* <FormControlLabel
          className={classes.filtersRow}
          control={
            <Checkbox
              className={classes.CheckboxInput}
              size="small"
              checked={forFutureMovements}
              onChange={(e) =>
                e.target.checked
                  ? setForFutureMovements(true)
                  : setForFutureMovements(false)
              }
            />
          }
          label={"Recordar este cambio para futuros movimientos con el mismo nombre"}
        /> */}
      </section>
      <div className={classes.buttons}>
        <ButtonText
          className="secondary"
          text="Cancelar"
          onClick={() => {
            navigate(Route.finance + '?#habits');
          }}
        />{' '}
        <ButtonPrimary
          className="first"
          text={textButton || 'Guardar cambios'}
          noDegraded
          onClick={() => {
            onSave();
          }}
        />
      </div>
    </div>
  );
};
