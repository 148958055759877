import React, { useState } from 'react';
import { Movement } from '@components/Structural/MyFinance/Movements/Movement/Movement';
import { navigate } from 'gatsby';
import { FinerioAccount, Route } from '@interfaces';
import { isBrowser, parseNumber } from '@utils';
import LayoutMyAccounts from '@components/MyAccounts/Layout/Layout';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import { LayoutOnBoarding, SEO } from '@components/Structural';
import { useAuth } from '@hooks';
import { TextInput } from '@components/Inputs';
import { BACK_ARROW_ICON, CALENDAR_ICON } from 'images/GoogleCloud';
import { DatePicker } from '@material-ui/pickers';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { getFinerioAccounts } from '@apollo';
import AmountInput from '@components/Reusables/AmountInput';

const editMovement = ({ location }) => {
  /*   useAuth({ redirectIfNotLogged: true });
   */
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 1240px)');
  const { finerioAccounts } = getFinerioAccounts();
  /* 
  if (isBrowser() && !location?.state) {
    navigate(Route.financialPortal);
  } */

  const movement = isBrowser() ? location?.state?.movement : '';
  
  let movementAccount: FinerioAccount[] | FinerioAccount = finerioAccounts.filter((acc) => acc.id === String(movement.accountId))

  if (movementAccount.length > 0) {
    movementAccount = movementAccount[0]
  }

  const [newDescription, setNewDescription] = useState<string>(
    movement?.description ?? ''
  );
  const [newAmount, setNewAmount] = useState<number>(
    Number(movement?.amount) ?? 0
  );
  const [newDate, setNewDate] = useState<Date>(
    new Date(movement?.date) ?? new Date()
  );

  const contentComponent = (
    <>
      <section className={classes.containerHeader}>
        <div className={classes.container}>
          <div className={classes.title}>
            <ArrowBackIosIcon onClick={() => navigate(Route.finance)} />
            <div>Recategorizar</div>
          </div>
          <div className={classes.containerBody}>
            <div>
              {movementAccount?.bankId != 0 ? (
                <>
                  <div className={classes.amount}>${parseNumber(newAmount, 2)}</div>
                  <div className={classes.date}>
                    <img src={CALENDAR_ICON} />
                    {newDate.toLocaleString('es').split(',')[0]}
                  </div>
                </>
              ) : (                  
                <>
                  <AmountInput
                    color='#F3F4F3'  
                    value={newAmount}
                    onChange={(e) => setNewAmount(e.target.value)}
                  />
                  <DatePicker
                    className={classes.datePicker}
                    name="date"
                    label={'Fecha'}
                    value={newDate}
                    onChange={(e) => setNewDate(e)}
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    openTo="date"
                    cancelLabel="Cancelar"
                  />
                </>
              )}
            </div>
            <div className={classes.cardDescription}>
              <div>Nombre</div>
              <TextInput
                className={classes.textFieldButton}
                label=" "
                onChange={(e) => setNewDescription(e.target.value as string)}
                value={newDescription}
                disabled={movementAccount?.bankId != 0}
              />
            </div>
          </div>{' '}
        </div>
      </section>
      <Movement movement={movement} movementAccount={movementAccount} newAmount={newAmount} newDate={newDate} newDescription={newDescription} />{' '}
    </>
  );

  return (
    <>
      <SEO
        title="Rokin - Mis hábitos - Editar movimiento"
        description="Edita movimientos creados con anterioridad"
      />

      {!isMobile ? (
        <LayoutOnBoarding activeFinance>{contentComponent}</LayoutOnBoarding>
      ) : (
        <>{contentComponent}</>
      )}
    </>
  );
};

export default editMovement;

export const useStyles = makeStyles((theme) => ({
  containerHeader: {
    background: '#001e28',
    color: 'white',
    minHeight: '25rem',
    marginTop: '-1.5rem',
    [theme.breakpoints.down(1240)]: {
      minHeight: '18rem',
      maxHeight: '25rem',
      height: '23rem',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '45.9rem',
    margin: '0 auto 0',
    paddingTop: '5rem',
    fontFamily: 'Montserrat',
    gap: '1rem',
    [theme.breakpoints.down(1240)]: {
      justifyContent: 'center',
      width: '90%',
      minWidth: 350,
    },
    '& img': {
      width: '1rem',
    },
    '& div': {
      [theme.breakpoints.down(1240)]: {
        minWidth: '50%',
      },
      textAlign: 'center',
    },
  },
  container: {
    maxWidth: '45.9rem',
    margin: '0 auto 0',
    minWidth: 350,
  },
  textFieldButton: {
    background: '#eaeaea',
    borderRadius: 5,
  },
  containerBody: {
    padding: '4.25rem 0 0',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down(1240)]: {
      flexDirection: 'column',
      textAlign: 'center',
      padding: '1.25rem 0 0',
    },
    '& >div': {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
    },
  },
  amount: {
    fontSize: '3rem',
    fontFamily: 'Montserrat',
    fontWeight: 500,
  },
  date: {
    display: 'flex',
    gap: '0.5rem',
    opacity: 0.5,
    letterSpacing: '0.2rem',
    [theme.breakpoints.down(1240)]: {
      justifyContent: 'center',
    },
  },
  cardDescription: {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    borderRadius: 13,
    width: '21.5rem',
    padding: '1rem 2rem',
    color: '#646464',
    gap: '0.4rem',
    [theme.breakpoints.down(1240)]: {
      margin: '2rem auto 0',
      textAlign: 'initial',
      width: '90%',
    },
    '& > div': {
      textTransform: 'uppercase',
      fontSize: '0.75rem',
      fontWeight: 'bold',
    },
  },
  datePicker: {
    "& .MuiInputBase-input": {
      color: "#F3F4F3",
    }
  }
}));
